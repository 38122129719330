<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="广告类型：">
        <el-select v-model="searchForm.type" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="图片广告" :value="1"></el-option>
          <el-option label="软文广告" :value="2"></el-option>
          <el-option label="视频广告" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告标题：">
        <el-input v-model="searchForm.title" size="small" placeholder="请输入广告标题"></el-input>
      </el-form-item>
      <el-form-item label="会员昵称：">
        <el-input v-model="searchForm.nickname" size="small" placeholder="请输入会员昵称"></el-input>
      </el-form-item>
      <el-form-item label="上架时间：">
        <el-date-picker
          v-model="searchForm.start_show_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="到期时间：">
        <el-date-picker
          v-model="searchForm.start_out_time"
          size="small"
          type="daterange"
          value-format="timestamp"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="上架状态：">
        <el-select v-model="searchForm.is_putaway" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="下架" :value="1"></el-option>
          <el-option label="上架" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据来源：">
        <el-select v-model="searchForm.comefrom" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="平台" :value="1"></el-option>
          <el-option label="会员" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button size="small" @click="search" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" row-key="id">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="sort" label="排序" width="120">
        <template #header>
          <div class="edit">
            <span>排序</span>
            <i class="el-icon-edit-outline"></i>
          </div>
        </template>
        <template v-slot="{ row }">
          <el-input size="small" type="Number" :min="0" @change="editSort(row)" v-model="row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="广告类型" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '图片广告' : row.type == 2 ? '软文广告' : '视频广告' }}
        </template>
      </el-table-column>
      <el-table-column prop="title" label="广告标题" align="center"></el-table-column>
      <el-table-column prop="comefrom" label="数据来源" align="center">
        <template v-slot="{ row }">
          {{ row.comefrom == 1 ? '平台' : '会员' }}
          <userInfo :name="row.nickname" :avatar="row.avatar"></userInfo>
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="会员昵称" align="center">
        <template v-slot="{ row }">{{ row.nickname || '--' }}</template>
      </el-table-column>
      <el-table-column prop="mobile" label="会员手机号" align="center">
        <template v-slot="{ row }">{{ row.mobile || '--' }}</template>
      </el-table-column>
      <el-table-column prop="picture" label="图片广告/视频封面/软文封面" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 120px; height: 60px" :src="row.picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="show_time" label="上架时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.show_time) }}</template>
      </el-table-column>
      <el-table-column prop="out_time" label="到期时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.out_time) }}</template>
      </el-table-column>
      <el-table-column prop="city_name" label="城市" align="center"></el-table-column>
      <el-table-column prop="status" label="展示状态" align="center"></el-table-column>
      <el-table-column prop="is_putaway" label="展示/下架" align="center">
        <template v-slot="{ row }">
          <el-switch v-model="row.is_putaway" @change="editPutaway(row)" :active-value="1" :inactive-value="0"></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="操作" align="center">
        <template v-slot="{ row }">
          <el-button @click="$router.push({ path: '/extension/advertisement/edit', query: { id: row.id } })" size="small" type="text">编辑</el-button>
          <el-button @click="del(row)" size="small" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import _ from 'lodash';
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      list: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        title: '',
        type: '',
        nickname: '',
        is_putaway: '',
        comefrom: '',
        start_show_time: '',
        end_show_time: '',
        start_out_time: '',
        end_out_time: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    searchClear() {
      this.searchForm = {
        page: 1,
        rows: 10,
        title: '',
        type: '',
        nickname: '',
        is_putaway: '',
        comefrom: '',
        start_show_time: '',
        end_show_time: '',
        start_out_time: '',
        end_out_time: '',
      };
      this.getList();
    },
    getList() {
      let searchForm = _.cloneDeep(this.searchForm);
      if (searchForm.start_show_time) {
        searchForm.start_show_time = searchForm.start_show_time[1] / 1000;
        searchForm.end_show_time = searchForm.start_show_time[0] / 1000;
      }
      if (searchForm.start_out_time) {
        searchForm.start_out_time = searchForm.start_out_time[1] / 1000;
        searchForm.end_out_time = searchForm.start_out_time[0] / 1000;
      }
      this.$axios.post(this.$api.gateway.Ad.list, searchForm).then(res => {
        if (res.code == 0) {
          console.log(res);
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    editSort(row) {
      this.$axios
        .post(this.$api.gateway.Ad.setSort, {
          id: row.id,
          sort: row.sort,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    editPutaway(row) {
      this.$axios
        .post(this.$api.gateway.Ad.setPutaway, {
          id: row.id,
          is_putaway: row.is_putaway,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('修改成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    del(row) {
      this.$confirm('此操作将永久删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.gateway.Ad.del, { id: row.id }).then(res => {
          if (res.code == 0) {
            this.getList();
            this.$message.success('删除成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    /deep/ .el-input__inner {
      border-color: #fff;
    }
    /deep/ .el-input__inner:focus {
      border-color: #409eff;
    }
    .edit {
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        margin-right: 5px;
      }
      i {
        font-size: 17px;
      }
    }
  }
}
</style>